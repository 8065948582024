import { Button } from "@doar/components";
import styled, { Row, Col } from "@doar/shared/styled";

export const StyledForm = styled.div`
    margin-top: 2rem;
    display: flex;
`;

export const StyledCol = styled(Col)`
    margin: 0;
    padding: 0;
`;
export const StyledRow = styled(Row)`
    padding-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
    label {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
        width: 100%;
    }
`;

export const StyledPhoneInputRow = styled(Row)`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;

    label {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }

    .PhoneInput {
        input {
            background-clip: padding-box;
            border: 1px solid #c0ccda;
            border-radius: 4px;
            background-color: #fff;
            color: #1b2e4b;
        }
    }

    .PhoneInput.invalid {
        input {
            border: 1px solid #dc3545;
        }
    }
`;

export const StyledGoogleMapRow = styled(Row)`
    padding: 1rem;
`;

export const StyledCheckboxRow = styled(Row)`
    margin-left: 1rem;
    > label {
        padding-top: 0.5rem;
        margin-left: -1rem;
    }
    padding-bottom: 1rem;
    display: flex;
    flex-direction: column;
`;

export const StyledCheckboxCol = styled(Col)`
    display: flex;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
`;

export const StyledCheckbox = styled(Row)`
    height: 2rem;
    padding: 0 1rem 0 1rem;
    label {
        padding-left: 1rem;
    }
`;

export const StyledButtonRow = styled.div`
    display: flex;
    margin-top: 4rem;
    flex-direction: column;
`;

export const StyledButton = styled(Button)`
    margin-right: 1rem;
`;

export const StyledTagRow = styled(Row)`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    label {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;
    }
    margin-left: 0;
    margin-right: 0;
`;

export const ErrorMessage = styled.div`
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
`;

export const StyledHighlightRow = styled.div`
    margin-top: -1rem;
    width: 100%;
    padding: 0.5rem 1rem 1rem 1rem;
`;

export const StyledSelectedGPAddonList = styled.div`
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledSelectedGPAddonRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
        border: none;
    }
`;

export const StyledDeleteGPAddonRow = styled.div`
    cursor: pointer;
`;

export const StyledPackageTableContainer = styled.div`
    padding: 4rem 0 0 0;
`;

export const StyledNotificationRuleRow = styled.div`
    width: 100%;
    padding-bottom: 1rem;

    label {
        padding-bottom: 0.5rem;
    }

    .notification-rule-row {
        align-items: center;
    }
`;

export const StyledEditorContainer = styled.div`
    .custom-editor {
        height: 275px !important;
        border: 1px solid #c0ccda; !important;
        
        padding: 5px !important;
        border-radius: 2px !important;
        background-color: white;
    }

    .custom-toolbar {
        border: 1px solid #c0ccda; !important;
    }
`;

export const StyledPreviewEditor = styled.div`
    width: 100%;

    .disabled-input {
        padding: 1rem;
        border: 1px solid #c0ccda;
        border-radius: 4px;
        background-color: #f5f6fa;
    }

    .fixed-height {
        height: 400px;
        overflow: scroll;
    }

    .preview-editor {
        @font-face {
            font-family: 'Montserrat';
            src: url("Montserrat-Regular.ttf") format('truetype');
        }
        
        @font-face {
            font-family: 'FjallaOne';
            src: url("FjallaOne-Regular.ttf") format('truetype');
        }
        
        html, body {
            font-size: 12px;
            background-color: transparent;
            color: white;
            font-family: "Montserrat"
        }
        
        h1{
            font-family: "FjallaOne";
            color: #15E4D8;
        }
        
        h2 {
            font-family: "Montserrat";
            color: #8E67E0;
            text-transform: uppercase;
            font-size: 1.2em;
        }

        h3 {
            font-family: "Montserrat";
            color: #15E4D8;
            text-transform: uppercase;
            font-size: 1.56em;
            font-weight: 400;
        }
        
        div.panel {
            margin-top: 20px;
            background-color: #3E3E3E;
            padding: 10px;
        }
        
        div.panel h3 {
            color: #15E4D8;
            font-family: "Montserrat"
            font-size: 1rem;
        }
        
        blockquote {
            width: 100%;
            margin: 0;
            display: block;
            background-color: #A147CB;
            padding: 20px 10px 20px 90px;
            background-image: url("https://stoke-static-files-dev.s3.eu-west-1.amazonaws.com/images/STAMP.png");
            background-repeat: no-repeat;
            background-position: 5px 100%;
            background-size: auto 90%;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: normal;
            border-left: none;

            div {
                margin-left: 10%;
            }
        }
        
        blockquote:before {
            content: "STOKE APP BENEFIT";
            display: block;
            font-family: "Montserrat";
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 5px;
            margin-left: 10%;

        }
    }
`;
