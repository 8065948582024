import {
    BookingTicketItem,
    LocationFormState,
    GPAddOns,
    LocationItem,
    LocationReview,
    PromoItem,
    PushNotificationItem,
    TravelPackageItem,
    TravelProductItem,
    PromoFormState,
    PromoFormBody,
    TravelPackageFormState,
    LocationAdminReview,
    PushNotificationFormState,
    TravelProductFormState,
    BookingImportResponse,
    BookingImportLogResponse,
    BookingImportItem,
} from "@doar/shared/types";
import axios from "axios";
import { getCookie, removeCookie, setCookie } from "typescript-cookie";
import { LoginValues } from "./components/signin-form";
import {
    INVALID_TOKEN_ERROR,
    NO_BOOKING_ID_ERROR,
    GENERAL_ERROR,
    TICKET_ALREADY_CHECKED_IN_ERROR,
    INVALID_CREDENTIAL_ERROR,
    NO_ADDON_ERROR,
} from "./errors";

const apiTestUrl = "https://api-test.thestoke.app";
const apiDevUrl = "https://api.thestoke.app";

const apiUrl = apiTestUrl;

// Set up axios for auth, api and check in requests
export const authRequests = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
});

export const apiRequests = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
});

export const checkinRequests = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json;charset=UTF-8",
    },
});

export enum COOKIE {
    Username = "stoke_admin_username",
    RefreshToken = "stoke_admin_refresh_token",
    AccessToken = "stoke_admin_token",
    AdminRole = "stoke_admin_role",
}

export enum ADMINTYPE {
    YesTrip = "yes_trips_admin",
    Super = "super_admin",
}

export async function RequestNewToken() {
    const username = getCookie(COOKIE.Username);
    const refreshToken = getCookie(COOKIE.RefreshToken);

    if (username && refreshToken) {
        const response = await fetch(`${apiUrl}/refresh`, {
            method: "POST",
            headers: {
                "content-type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({ refreshToken, username }),
        });

        if (response.status === 200) {
            const {
                accessToken,
                refreshToken: newToken,
                role,
            }: AuthTokens = await response.json();

            setCookie(COOKIE.AccessToken, accessToken, {
                expires: 1,
                path: "/",
            });
            setCookie(COOKIE.RefreshToken, newToken, {
                expires: 30,
                path: "/",
            });
            setCookie(COOKIE.AdminRole, role, {
                expires: 1,
                path: "/",
            });
        }

        if (response.status === 401) {
            removeCookie(COOKIE.AccessToken);
            removeCookie(COOKIE.RefreshToken);
            removeCookie(COOKIE.Username);
            removeCookie(COOKIE.AdminRole);
            window.location.href = "/";
        }
    }
}

apiRequests.interceptors.request.use((config) => {
    const controller = new AbortController();
    const token = getCookie(COOKIE.AccessToken);
    const newConfig = { ...config };

    if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
    } else {
        controller.abort();
    }

    return {
        ...newConfig,
        signal: controller.signal,
    };
});

checkinRequests.interceptors.request.use((config) => {
    const controller = new AbortController();
    const token = getCookie(COOKIE.AccessToken);
    const newConfig = { ...config };

    if (token) {
        newConfig.headers.Authorization = `Bearer ${token}`;
    } else {
        controller.abort();
    }

    return {
        ...newConfig,
        signal: controller.signal,
    };
});

apiRequests.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;

        if (error.response) {
            if (error.response.status === 401) {
                await RequestNewToken();
                return apiRequests(originalConfig);
            }
            if (error.response.status === 403) {
                const adminRole = getCookie(COOKIE.AdminRole);

                if (adminRole && adminRole === ADMINTYPE.YesTrip) {
                    window.location.href = "/booking-imports";
                }
            }
        }

        return Promise.reject(error);
    }
);

type AuthTokens = {
    accessToken: string;
    refreshToken: string;
    username: string;
    role: ADMINTYPE;
};

export async function login({ username, password }: LoginValues) {
    const response = await authRequests.post("/login", { username, password });

    if (response.status === 200) {
        const {
            accessToken,
            refreshToken,
            role,
        }: AuthTokens = await response.data;
        return { accessToken, refreshToken, role };
    }

    if (response.status === 401) {
        throw new Error(INVALID_CREDENTIAL_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

// Location

export async function fetchAllLocations() {
    const response = await apiRequests.get("/admin/venues");

    if (response.status === 200) {
        const locations: LocationItem[] = await response.data;
        return locations;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchLocation(locationId: string) {
    const response = await apiRequests.get(`/admin/venues/${locationId}`);

    if (response.status === 200) {
        const location: LocationItem = await response.data;
        return location;
    }

    throw new Error(GENERAL_ERROR);
}

export async function createLocation(body: LocationFormState) {
    const response = await apiRequests.post("/admin/venues", { ...body });

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function updateLocation(id: string, body: LocationFormState) {
    const response = await apiRequests.put(`admin/venues/${id}`, { ...body });
    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function deleteLocation(locationId: string) {
    const response = await apiRequests.delete(`admin/venues/${locationId}`);

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

// Products

export async function fetchAllTravelProducts() {
    const response = await apiRequests.get(`/admin/products`);

    if (response.status === 200) {
        const travelProducts: TravelProductItem[] = await response.data;
        return travelProducts;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchTravelProduct(productId: string) {
    const response = await apiRequests.get(`/admin/products/${productId}`);

    if (response.status === 200) {
        const travelProduct: TravelProductItem = await response.data;
        return travelProduct;
    }

    throw new Error(GENERAL_ERROR);
}

interface CreateTravelProductResponse {
    data: {
        id: string;
    };
    status: number;
}

export async function createTravelProduct(body: TravelProductFormState) {
    const response: CreateTravelProductResponse = await apiRequests.post(
        "/admin/products",
        { ...body }
    );

    if (response.status === 200) {
        return response.data;
    }

    throw new Error(GENERAL_ERROR);
}

export async function updateTravelProduct(
    id: string,
    body: TravelProductFormState
) {
    const response = await apiRequests.put(`/admin/products/${id}`, {
        ...body,
    });

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function deleteTravelProduct(productId: string) {
    const response = await apiRequests.delete(`/admin/products/${productId}`);

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchAllProductTags() {
    const response = await apiRequests.get("/admin/product-tags");
    if (response.status === 200) {
        /* eslint-disable */
        const { tags } = await response.data;
        return tags;
    }
    throw new Error(GENERAL_ERROR);
}

// Packages

export async function fetchGPPackages() {
    const response = await apiRequests.get(`/admin/packages/external`);

    if (response.status === 200) {
        const { packages } = await response.data;
        /* eslint-disable */
        return packages;
    }

    throw new Error(GENERAL_ERROR);
}

// TODO: type packages
export async function fetchPackages(productId: string) {
    const response = await apiRequests.get(
        `/admin/products/${productId}/packages`
    );

    if (response.status === 200) {
        const { offset, packages } = await response.data;
        /* eslint-disable */
        return packages;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchPackage(productId: string, packageId: string) {
    const response = await apiRequests.get(
        `/admin/products/${productId}/packages/${packageId}`
    );

    if (response.status === 200) {
        const travelPackage: TravelPackageItem = await response.data;
        /* eslint-disable */
        return travelPackage;
    }

    throw new Error(GENERAL_ERROR);
}

export async function createPackage(
    productId: string,
    body: TravelPackageFormState
) {
    const response = await apiRequests.post(
        `/admin/products/${productId}/packages`,
        { ...body }
    );

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function updatePackage(
    productId: string,
    packageId: string,
    body: TravelPackageFormState
) {
    const response = await apiRequests.put(
        `/admin/products/${productId}/packages/${packageId}`,
        { ...body }
    );

    if (response.status === 200) {
        const travelPackage: TravelPackageItem = await response.data;
        /* eslint-disable */
        return travelPackage;
    }

    throw new Error(GENERAL_ERROR);
}

export async function deletePackage(productId: string, packageId: string) {
    const response = await apiRequests.delete(
        `/admin/products/${productId}/packages/${packageId}`
    );

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

// Add Ons

export async function fetchGPAddOns() {
    const response = await apiRequests.get(`/admin/addons/external`);

    if (response.status === 200) {
        const addons: GPAddOns[] = await response.data;
        /* eslint-disable */
        return addons;
    }

    if (response.status === 400) {
        throw new Error(NO_ADDON_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

// Promo

export async function fetchAllPromos() {
    const response = await apiRequests.get(`/admin/promos`);
    if (response.status === 200) {
        const promos: PromoItem[] = await response.data;
        return promos;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchPromo(promoId: string) {
    const response = await apiRequests.get(`/admin/promos/${promoId}`);

    if (response.status === 200) {
        const promo: PromoItem = await response.data;
        return promo;
    }

    throw new Error(GENERAL_ERROR);
}

export async function createPromo(body: PromoFormBody) {
    const response = await apiRequests.post(`/admin/promos`, { ...body });

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function updatePromo(promoId: string, body: PromoFormBody) {
    const response = await apiRequests.put(`/admin/promos/${promoId}`, {
        ...body,
    });

    if (response.status === 200) {
        return;
    }
    throw new Error(GENERAL_ERROR);
}

export async function deletePromo(promoId: string) {
    const response = await apiRequests.delete(`/admin/promos/${promoId}`);
    if (response.status === 200) {
        return;
    }
    throw new Error(GENERAL_ERROR);
}

// Notification

export async function fetchAllNotifiacations() {
    const response = await apiRequests.get(`/admin/notifications`);
    if (response.status === 200) {
        const notifications: PushNotificationItem[] = await response.data;
        return notifications;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchNotification(notificationId: string) {
    const response = await apiRequests.get(
        `/admin/notifications/${notificationId}`
    );

    if (response.status === 200) {
        const notification: PushNotificationItem = await response.data;
        return notification;
    }

    throw new Error(GENERAL_ERROR);
}

export async function createNotification(body: PushNotificationFormState) {
    const response = await apiRequests.post(`/admin/notifications/`, {
        ...body,
    });

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function upadateNotification(
    id: string,
    body: PushNotificationFormState
) {
    const response = await apiRequests.put(`/admin/notifications/${id}`, {
        id,
        ...body,
    });

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

export async function deleteNotifcation(notificationId: string) {
    const response = await apiRequests.delete(
        `/admin/notifications/${notificationId}`
    );

    if (response.status === 200) {
        return;
    }

    throw new Error(GENERAL_ERROR);
}

// Synchronize packages with GP
export async function syncAllPackages() {
    const response = await apiRequests.post("/admin/packages/synchronise");

    if (response.status === 200) {
        return;
    }
    throw new Error(GENERAL_ERROR);
}

export async function syncPackage(packageId: string) {
    const response = await apiRequests.post(`/admin/packages/${packageId}/synchronise
    `);

    if (response.status === 200) {
        return response.data;
    }
    throw new Error(GENERAL_ERROR);
}

export async function syncPackages(productId: string) {
    const response = await apiRequests.post(
        `/admin/products/${productId}/packages/synchronise`
    );

    if (response.status === 200) {
        return response.data;
    }
    throw new Error(GENERAL_ERROR);
}

// Check in
export async function fetchTicketsDetails(bookingId: number) {
    const response = await checkinRequests.get(
        `/bookings/${bookingId}/tickets`
    );

    if (response.status === 200) {
        const tickets: BookingTicketItem[] = await response.data;
        return tickets;
    }

    if (response.status === 401) {
        throw new Error(INVALID_TOKEN_ERROR);
    }

    if (response.status === 404) {
        throw new Error(NO_BOOKING_ID_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

export async function checkInTicket(bookingId: number, ticketId: number) {
    const response = await checkinRequests.post(
        `/bookings/${bookingId}/tickets/${ticketId}/checkin`
    );

    if (response.status === 200) {
        const { totalCheckedIn, totalTickets } = await response.data;
        return { totalCheckedIn, totalTickets };
    }

    if (response.status === 401) {
        throw new Error(INVALID_TOKEN_ERROR);
    }

    if (response.status === 400) {
        throw Error(TICKET_ALREADY_CHECKED_IN_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

// File upload
export async function uploadImage(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiRequests.post(`/admin/upload`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    if (response.status === 200) {
        const { location } = await response.data;
        return location;
    }

    throw new Error(GENERAL_ERROR);
}

// Yes trips import bookings
export async function uploadBookingFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiRequests.post(
        "/admin/yes-trips/bookings/imports/upload",
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    );

    if (response.status === 200) {
        const uploadStatus: BookingImportItem = await response.data;
        return uploadStatus;
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchAllBookingImports() {
    const response = await apiRequests.get(`/admin/yes-trips/bookings/imports`);

    if (response.status === 200) {
        const imports: BookingImportResponse = await response.data;
        return imports;
    }

    if (response.status === 400) {
        // TODO: show message coming from B/E
        throw new Error(GENERAL_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchBookingImportDetail(id: string) {
    const response = await apiRequests.get(
        `admin/yes-trips/bookings/imports/${id}`
    );

    if (response.status === 200) {
        const importDetail: BookingImportItem = await response.data;
        return importDetail;
    }

    if (response.status === 400) {
        throw new Error(GENERAL_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

export async function fetchBookingImportLogs(id: string, offset: number) {
    const response = await apiRequests.get(
        `/admin/yes-trips/bookings/imports/${id}/logs?offset=${offset}`
    );

    if (response.status === 200) {
        const importLogs: BookingImportLogResponse = await response.data;
        return importLogs;
    }

    if (response.status === 400) {
        throw new Error(GENERAL_ERROR);
    }

    throw new Error(GENERAL_ERROR);
}

export async function downloadBookingImportFile(fileLink: string) {
    const response = await apiRequests.get(
        `admin/files/download?fileLink=${fileLink}`
    );

    if (response.status === 200) {
        const { location } = await response.data;

        // Initiate download
        const downloadLink = document.createElement("a");
        downloadLink.href = location;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        return;
    }

    throw new Error(GENERAL_ERROR);
}

// TODO: phase 2
// export async function fetchLocationReviews(locationId: string) {
//     const token = getCookie("stoke_admin_token");
//     const headers: HeadersInit = token
//         ? {
//               "content-type": "application/json;charset=UTF-8",
//               Authorization: `Bearer ${token}`,
//           }
//         : {
//               "content-type": "application/json;charset=UTF-8",
//           };
//     const response = await fetch(`${apiUrl}/venue/${locationId}/review`, {
//         method: "GET",
//         headers,
//     });

//     if (response.status === 200) {
//         const reviews: LocationAdminReview[] = await response.json();
//         return reviews;
//     }

//     throw new Error(GENERAL_ERROR);
// }
