import styled, { themeGet, tinycolor } from "@doar/shared/styled";
import { ErrorMessage, Field, FieldHookConfig, FieldProps } from "formik";
import { useEffect } from "react";
import Select, {
    ActionMeta,
    CSSObjectWithLabel,
    MultiValue,
    SingleValue,
} from "react-select";

export const CustomError = styled.div`
    color: red;
    line-height: 1rem;
    margin-top: 0.5rem;
`;

export const BasicFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    textarea {
        min-height: 5rem;
        display: block;
        width: 100%;
        padding: 0.46875rem 0.625rem;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        color: #1b2e4b;
        border: 1px solid #c0ccda;
        border-radius: 4px;

        ::placeholder {
            color: #c1bfbf;
            opacity: 0.8;
        }
    }

    input {
        ::placeholder {
            color: #c1bfbf;
            opacity: 0.8;
        }
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #1b1b1b inset !important;
        // -webkit-text-fill-color: var(--text-white) !important;
    }
`;

const BasicField = styled(Field)`
    display: block;
    width: 100%;
    height: 2.375rem;
    padding: 0.46875rem 0.625rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1b2e4b;
    border: 1px solid #c0ccda;
    border-radius: 4px;

    ::placeholder {
        color: #c1bfbf;
        opacity: 0.8;
    }
`;

export const BasicFieldLabel = styled.label`
    display: flex;
    font-weight: 400;
    line-height: 1.25rem;

    div {
        font-size: 1.25rem;
        font-weight: 300;
    }
`;

const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    padding-left: 0.25rem;

    input {
        height: 1rem;
        width: 1rem;
        margin-right: 1rem;
        margin-left: -0.75rem;
        border: 1px solid ${themeGet("colors.gray500")};
        color: #001737;
        background: transparent;

`;

interface Props {
    name: string;
    label?: string;
    required?: boolean;
}

export default function BasicFieldWithError({
    name,
    label,
    required,
    type,
    ...props
}: FieldHookConfig<string> & Props) {
    const renderField = () => {
        switch (type) {
            case "textarea":
                return (
                    <>
                        <BasicFieldLabel htmlFor={name}>
                            {label} {required && <div>*</div>}
                        </BasicFieldLabel>
                        <Field {...props} name={name} as="textarea" />
                    </>
                );

            case "checkbox":
                return (
                    <CheckboxContainer>
                        <Field type="checkbox" name={name} />
                        <span>{label}</span>
                    </CheckboxContainer>
                );

            default:
                return (
                    <>
                        <BasicFieldLabel htmlFor={name}>
                            {label} {required && <div>*</div>}
                        </BasicFieldLabel>
                        <FieldContainer>
                            <BasicField {...props} type={type} name={name} />
                        </FieldContainer>
                    </>
                );
        }
    };

    return (
        <BasicFieldContainer>
            {renderField()}
            <ErrorMessage component={CustomError} name={name} />
        </BasicFieldContainer>
    );
}

export interface SelectOption {
    label: string;
    value: string;
}

const CustomSelectStyle = {
    control: (base: CSSObjectWithLabel) => ({
        ...base,
        height: "2.375rem",
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
        ...base,
        height: "2.375rem",
        padding: "0 0.5rem",
    }),
    input: (base: CSSObjectWithLabel) => ({
        ...base,
        height: "2.375rem",
        margin: "0",
        padding: "0",
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
        ...base,
        color: "#c1bfbf",
        opacity: "0.8",
    }),
};

// Helper function for converting enums to select options
export const convertToSelectOption = (
    enumInstance: { [x: string]: string },
    // @ts-ignore
    getLabel?: (val: any) => string
) => {
    return Object.keys(enumInstance).map((k) => ({
        value: k,
        label: getLabel
            ? getLabel(k)
            : enumInstance[k as keyof typeof enumInstance].toLowerCase(),
    }));
};

interface BasicSelectWithErrorProps extends FieldProps {
    options: SelectOption[];
    className?: string;
    placeholder?: string;
    defaultValue: string;
    label?: string;
    required?: boolean;
    isMulti?: boolean;
    disabled?: boolean;
    postChange?: (newValue: SelectOption) => void;
}

export function BasicSelectWithError({
    className,
    placeholder,
    field,
    form,
    options,
    defaultValue,
    label,
    required,
    isMulti = false,
    disabled,
    postChange,
}: BasicSelectWithErrorProps) {
    const onChange = (
        newValue:
            | MultiValue<string | SelectOption>
            | SingleValue<string | SelectOption>,
        actionMeta: ActionMeta<string | SelectOption>
    ) => {
        form.setFieldValue(field.name, (newValue as SelectOption).value);

        // Update dependent field
        if (postChange) {
            postChange(newValue as SelectOption);
        }
    };

    const getValue = () => {
        if (options) {
            return options.find((option) => option.value === field.value);
        }
        return isMulti ? [] : "";
    };

    return (
        <BasicFieldContainer>
            <BasicFieldLabel htmlFor={field.name}>
                {label} {required && <div>*</div>}
            </BasicFieldLabel>
            <Select
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={className}
                name={field.name}
                value={getValue()}
                onChange={onChange}
                options={options}
                isMulti={isMulti}
                styles={CustomSelectStyle}
                isDisabled={disabled}
            />
        </BasicFieldContainer>
    );
}
